.App {
    width: 100%;
    height: 100vh;
    text-align: center;
}

.section {
    margin: 4rem auto !important;
    padding: 2rem 4rem;
    color: black;
    min-height: 100%;
}

.section-mobile {
    color: black;
    min-height: 100%;
}
