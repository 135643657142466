/* For mobile phones: */
html {
    font-size: 1.75vmax;
}

.store-buttons {
    display: none !important;
}

.store-buttons-mobile {
    display: none !important;
}

/* For Tablets: */
@media only screen and (min-width: 481px) {
    html {
        font-size: 1.25vmin;
    }

    .store-buttons {
        /* padding-top: 4vmax; */
        display: flex !important;
        max-height: 8vmax;
    }

    .explosion {
        height: 60vh;
    }

    .mockup {
        height: 37vh;
    }
}

/* For Landscape Tablets: */
@media only screen and (min-width: 1025px) {
    html {
        font-size: 1.5vmin;
    }

    .store-buttons-mobile {
        display: none !important;
    }

    .store-buttons {
        /* padding-top: 4vmax; */
        display: flex !important;
        max-height: 8vmax;
    }

    .store-buttons > a > img {
        height: 100%;
    }

    .explosion {
        height: 80vh;
    }

    .mockup {
        height: 67vh;
    }
}

/* For desktops: */
@media only screen and (min-width: 1399px) {
    html {
        font-size: 2vmin;
    }

    .store-buttons {
        display: none !important;
    }

    .store-buttons-mobile {
        display: none !important;
    }

    .explosion {
        height: 100vh;
    }

    .mockup {
        height: 100vh;
    }
}

html,
body,
#root {
    scroll-behavior: smooth;

    margin: 0;
    padding: 0;
    /* height: 100%; */
    background-color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
